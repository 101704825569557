<template>
    <div class="cert-print">
        <el-row :gutter="20">
            <el-col :span="24">
                <h2 align="center" class="mb-20">{{ $t('data.print.printPreview') }}</h2>
            </el-col>
            
                <el-col :span="24">
                    <div id="certView">
                        <div class="cert-view" v-for="(item, index) in printList" :key="index">
                            <img :src="item.picture" alt="">
                        </div>
                    </div>
                </el-col>


                <el-col :span="24" align="center" class="mt-20">
                    <!-- <el-button v-print="'#certView'">{{ $t('data.print.title') }}</el-button> -->
                    <el-button @click="openPdf">{{ $t('data.print.title') }}</el-button>
                </el-col>

        </el-row>
    </div>
</template>

<script>
import { simpleCerts,certsDetails,certsDownload } from '../../api/index'
export default {
    name: 'cert print',
    components: {
    },
    data() {
        return {
            printList:[],
            pdf: ''
        }
    },
    mounted() {
        this.initPageData();
    },
    methods:{
        initPageData(){
            if(this.$route.query.template_id){
                let apiData = {
                    id: this.$route.query.template_id
                }
                simpleCerts({apiData}).then( res => {
                    this.printList = res.data;
                })
            } else if(this.$route.query.cert_id){
                let apiData = {
                    id: this.$route.query.cert_id
                }
                certsDetails({apiData}).then( res => {
                    this.printList.push(res.data);
                    this.pdf = res.data.pdf;
                })
            }
            
        },
        openPdf(){
            if(this.$route.query.template_id){
                let apiData = {
                    id: this.$route.query.template_id
                }
                certsDownload({apiData}).then( res => {
                    window.open(res.data.url);
                })
            } else if(this.$route.query.cert_id){
                window.open(this.pdf);
            }
            
        }
    }
}
</script>



<style>
@media print {
  .cert-view{
    -webkit-print-color-adjust:exact;
    -moz-print-color-adjust:exact;
    -ms-print-color-adjust:exact;
    page-break-before:always;
    margin: 0 !important;
  } 
  html, body {
        height: inherit;
    }
  #certView{
    height: inherit !important;
    overflow: inherit;
  }
}

.cert-print .cert-view{
  margin: 20px auto;
  text-align: center;
}
</style>

<style scoped>
@page {  
  size: 800px 1420px;
  margin-top: 0mm;
  margin-bottom: 0mm;
  margin-left: 0mm; 
  margin-right: 0mm
}

.cert-view{
  background-color: #cfeeff;
  background-repeat:no-repeat;
  background-position: center center;
  background-size: cover;
  width: 800px;
  height: 1420px;

}
.cert-view img{
    width: 100%;
    height: auto;
}
</style>
